body {
    background-image: url('login_bg.png');
    margin: 0;
    font-family: Arial, sans-serif;
}

.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 10px;
}

.containerap {
    width: 100%;
    max-width: 500px;
    margin: 20px;
    padding: 16px;
    background-color: rgb(235, 230, 230);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

    .containerap div {
        padding-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

.centerDiv {
    display: block !important;
}

    .centerDiv h1 {
        color: cornflowerblue;
    }

.containerap label {
    width: 40%;
    text-align: right;
    padding-right: 10px;
}

.containerap input {
    width: 60%;
}

.App-logo {
    height: 15vmin;
    pointer-events: none;
}

.App-img {
    height: 15vmin;
    background-image: url(./iit-k.jpeg);
    width: 60%;
}

main {
    margin: auto;
    text-align: center;
}

    main button {
        margin-top: 20px;
        background-color: #2D8CFF;
        color: #ffffff;
        padding: 10px 40px;
        display: inline-block;
        border-radius: 10px;
        cursor: pointer;
        border: none;
        outline: none;
    }

        main button:hover {
            background-color: #2681F2;
        }

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .containerap {
        padding: 12px;
    }

        .containerap label {
            width: 35%;
            padding-right: 8px;
        }

        .containerap input {
            width: 65%;
        }

    .App-logo, .App-img {
        height: 20vmin;
    }

    main button {
        padding: 8px 30px;
    }
}

@media screen and (max-width: 480px) {
    .containerap {
        padding: 10px;
    }

        .containerap label {
            width: 100%;
            text-align: left;
            padding-right: 0;
            padding-bottom: 5px;
        }

        .containerap input {
            width: 100%;
        }

    .App-logo, .App-img {
        height: 25vmin;
    }

    main button {
        width: 100%;
        padding: 10px;
    }
}
